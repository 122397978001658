import React, { useState } from 'react';
import { Box, IconButton, Popover, Tabs, Tab, Tooltip } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import EmojiPicker from 'emoji-picker-react';

const accents = [
    'á', 'à', 'â', 'ä', 'ã', 'å', 'ā', 'æ', 'ç', 
    'é', 'è', 'ê', 'ë', 'ē', 'ė', 'ę', 
    'í', 'ì', 'î', 'ï', 'ī', 
    'ñ', 
    'ó', 'ò', 'ô', 'ö', 'õ', 'ø', 'ō', 'œ', 
    'ú', 'ù', 'û', 'ü', 'ū', 
    'ý', 'ÿ', 
    'Á', 'À', 'Â', 'Ä', 'Ã', 'Å', 'Ā', 'Æ', 'Ç', 
    'É', 'È', 'Ê', 'Ë', 'Ē', 'Ė', 'Ę', 
    'Í', 'Ì', 'Î', 'Ï', 'Ī', 
    'Ñ', 
    'Ó', 'Ò', 'Ô', 'Ö', 'Õ', 'Ø', 'Ō', 'Œ', 
    'Ú', 'Ù', 'Û', 'Ü', 'Ū', 
    'Ý', 'Ÿ'
];

function AccentsEmojiSelector({ onInsertAtCursor }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Tooltip title="Insert Emoji or Accent">
                <IconButton
                    disableRipple
                    sx={{
                        minHeight: '40px',
                        background: "#F5F5F5",
                        borderRadius: "5px",
                        alignSelf: 'flex-start'
                    }}
                    onClick={handleOpen} 
                    color="secondary"
                >
                    <InsertEmoticonIcon />
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        width: '400px', // Set the desired width
                        height: '525px', // Set the desired height
                        overflow: 'hidden', // Prevent the entire popover from scrolling
                    },
                }}
            >
                {/* Tab Header */}
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    sx={{
                        position: 'sticky', // Make the tabs sticky at the top
                        top: 0,
                        zIndex: 1,
                        backgroundColor: '#FFFFFF', // To ensure it's on top of the content
                    }}
                >
                    <Tab label="Emoji" />
                    <Tab label="Accents" />
                </Tabs>

                {/* Scrollable Content */}
                <Box sx={{ p:2,overflowY: 'auto' }}>
                    {tabIndex === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                justifyContent: 'center',
                            }}
                        >
                            <EmojiPicker
                                onEmojiClick={(emojiData, event) => {
                                    onInsertAtCursor(emojiData.emoji);
                                }}
                                autoFocusSearch={false}
                                skinTonesDisabled
                                previewConfig={{
                                    showPreview: false
                                }}
                                lazyLoadEmojis={true}
                            />
                        </Box>
                    )}
                    {tabIndex === 1 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                justifyContent: 'center',
                            }}
                        >
                            {accents.map((accent) => (
                                <IconButton
                                    onClick={() => {
                                        onInsertAtCursor(accent);
                                    }}
                                    size='small'
                                    sx={{
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        fontSize: '18px',
                                        width: 40,
                                    }}
                                >
                                    {accent}
                                </IconButton>
                            ))}
                        </Box>
                    )}
                </Box>
            </Popover>
        </React.Fragment>
    );
}

export default AccentsEmojiSelector;
