import React, { useEffect, useState } from 'react'
import { Dialog,DialogContent,DialogActions,Typography,Slide, FormControl, TextField,Button, Grid, DialogTitle, Select, InputAdornment, MenuItem} from '@mui/material';
import { useAppStore } from '../Main/AppStore.jsx';
import { phoneCountryCodes } from '../../utils/phoneFunctions.js';
import { createContact as createContactInServer } from '../../services/conversations.js';
import phone from 'phone';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function CreateContactInitializer({dialogOpen,handleDialogClose,prefilData}) {
    const [formData,setFormData]=useState({firstName:'',lastName:'',email:''})
    const [phoneNumber,setPhoneNumber]=useState({countryCode:"",number:""})
    
    const handlePhoneNumberFieldChange = (field, newValue) => {
        setPhoneNumber((prev) => {
            const updated = { ...prev };
            updated[field] = newValue;
            return updated;
        });
    };
    
    const handleFormDataChange = (field, value) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: value,
        }));
    };

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for validating email format
    const isFormValid = () => {
        const isEmailValid = emailPattern.test(formData.email); // Check if email matches pattern
        const areFormFieldsFilled = 
            formData.firstName.trim() !== '' && 
            formData.lastName.trim() !== '' && 
            formData.email.trim() !== '' && 
            isEmailValid;
        const isPhoneNumberValid = phone(phoneNumber.number, { country: phoneNumber.countryCode }).isValid;
        return areFormFieldsFilled && isPhoneNumberValid;
    };


    const createContact=async()=>{
        const contact={...formData,phoneNumber:phone(phoneNumber.number, { country: phoneNumber.countryCode }).phoneNumber}
        const conversation={channel:prefilData?.channel,conversationId:prefilData?.conversationId}
        await createContactInServer({contact,conversation})
        handleDialogClose()
        setFormData({firstName:'',lastName:'',email:''})
        setPhoneNumber({countryCode:"",number:""})
    }

    useEffect(()=>{
        if(prefilData){
            if(prefilData?.conversationId!=null){
                const recipient=phone(prefilData.conversationId)
                recipient.isValid && setPhoneNumber({countryCode:recipient.countryIso2,number:recipient.phoneNumber.replace(recipient.countryCode,'')})
                const profileName = prefilData.variables?.customer?.profileName??null
                if (profileName) {
                    const nameParts = profileName.split(' '); 
                    const firstName = nameParts[0];
                    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : ''; // Join the rest as last name
                    setFormData({
                        firstName: firstName,
                        lastName: lastName,
                        email: ''
                    });
                }
            }
        }
    },[prefilData])


    return(
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Create Contact
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: "auto",
                    minHeight:'30vh',
                    scrollbarWidth: "0.2em", // Firefox
                    msOverflowStyle: "none", // IE and Edge
                    "&::-webkit-scrollbar": {
                        width: "0.2em", // Adjust as needed
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent", // Make the track invisible
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#DF6437", // Make the thumb (scrollbar) invisible
                        borderRadius: "50px",
                    },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl fullWidth sx={{gap:0.5}}>
                            <Typography>First Name</Typography>
                            <TextField color='secondary' size='small' value={formData?.firstName} onChange={(e)=>{handleFormDataChange("firstName",e.target.value)}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl fullWidth sx={{gap:0.5}}>
                            <Typography>Last Name</Typography>
                            <TextField color='secondary' size='small' value={formData?.lastName} onChange={(e)=>{handleFormDataChange("lastName",e.target.value)}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl fullWidth sx={{gap:0.5}}>
                            <Typography>Email</Typography>
                            <TextField 
                                color='secondary' 
                                size='small' 
                                value={formData?.email} 
                                onChange={(e)=>{handleFormDataChange("email",e.target.value)}}
                                type='email'
                                error={formData.email.trim() !== '' && !emailPattern.test(formData.email)} // Show error when invalid
                                helperText={formData.email.trim() !== '' && !emailPattern.test(formData.email) ? "Invalid email address" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormControl fullWidth>
                            <Typography sx={{ fontFamily: 'DM Sans Medium' }}>Country</Typography>
                            <Select
                                color='secondary'
                                sx={{ background: '#FFFFFF' }}
                                size='small'
                                value={phoneNumber.countryCode}
                                onChange={(e) => { handlePhoneNumberFieldChange('countryCode', e.target.value) }}
                            >
                                {phoneCountryCodes.map((country, i) => (
                                    <MenuItem
                                        key={i}
                                        value={country.code}
                                        sx={{
                                            fontFamily: "DM Sans Regular",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {country.name}&nbsp;&nbsp;({country.dialCode})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <FormControl fullWidth>
                            <Typography sx={{ fontFamily: 'DM Sans Medium' }}>Phone number</Typography>
                            <TextField
                                error={!phone(phoneNumber.number, { country: phoneNumber.countryCode }).isValid}
                                color='secondary'
                                size="small"
                                sx={{ background: '#FFFFFF' }}
                                value={phoneNumber.number}
                                onChange={(e) => { handlePhoneNumberFieldChange('number', e.target.value.replace(/\D/g, '')) }}
                                inputProps={{
                                    maxLength: 20
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {phoneNumber.number.length}/20
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid> 
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' variant='contained' disableFocusRipple disableRipple disableTouchRipple  disabled={!isFormValid()} onClick={createContact}> Create </Button>
            </DialogActions>
        </Dialog>
    )
}



CreateContactInitializer.defaultProps = {
    dialogOpen:false,
    setDialogOpen:()=>{console.log("Closed the Dialog")},
    prefilData: {},
};

export default CreateContactInitializer